/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* layout-large.less 2013-1-16 *******/
#home {
  margin: 0 0 10px;
}
.mood #head {
  float: right;
  width: 32.96193129%;
}
@media screen and (min-width: 968px) {
  .mood #head {
    width: 30.73351903%;
  }
}
.banner {
  font-size: 18px;
  padding-top: 2.9em;
  padding-bottom: 18px;
}
.cb-layout2 .banner {
  padding-bottom: 30px;
}
.desk {
  width: 98%;
  max-width: 1077px;
}
.mood {
  max-width: 1600px;
  margin: 0 auto;
}
.mood .desk {
  position: relative;
  min-height: 700px;
}
#expo {
  position: absolute;
  right: 36.67595172%;
  padding: 0;
  margin: 0;
  height: 100%;
  border: 0;
}
@media screen and (min-width: 968px) {
  #expo {
    right: 32.96193129%;
  }
}
#slides {
  right: 0;
  max-height: none !important;
}
.slide {
  right: 0;
  height: 100% !important;
}
#expo .cb-landscape {
  width: auto !important;
  height: 100% !important;
}
#searchfield {
  background: #dddbd8;
}
.adressblock {
  flex-direction: row;
}
.desk > div.sub1 .menu {
  padding-right: 0.62em;
  padding-left: 0.62em;
}
.cb-toggle,
.togglenavigation {
  display: none;
}
.navigation--fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  border-top: 6px solid #4b85a0;
  background: #fff;
  z-index: 2000;
}
.desk > div.sub1 {
  display: block;
  float: right;
}
.desk > div.sub1 > .item {
  position: relative;
}
.desk > div.sub1 > .item > .menu {
  padding-top: 0.6em;
  padding-bottom: 0.9em;
  color: #000;
  letter-spacing: 0.02em;
}
.desk > div.sub1 > .exit > .menu {
  padding-right: 0;
}
.desk > div.sub1 > .item > .menu:hover,
.desk > div.sub1 > .item > .menu:focus,
.desk > div.sub1 > .item > a.path {
  color: #4b85a0;
}
div.sub2 {
  position: absolute;
  top: 2.7em;
  left: -2000px;
  z-index: 2;
  background: #4b85a0;
  padding: 1em 2000px 0.9em;
}
.item-empty > div.sub2 {
  padding: 0 !important;
}
div.sub2 > .item {
  width: 400px;
}
.navigation {
  position: relative;
  top: 40px;
  z-index: 1;
  display: block;
  margin: 0;
  height: 0;
  width: 100%;
}
.navigation > div.sub1 {
  display: none;
}
.auth,
.service_contact {
  font-size: 16px;
  font-size: 1.6rem;
  margin-left: 10px;
}
#find {
  float: right;
}
#searchfield {
  padding-left: 1em;
  margin: 0;
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0.27855153%;
  margin-left: 0.27855153%;
  width: 99.44289694%;
}
.area h2,
.area .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.area .part,
.area > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area > .slim {
  width: 32.77623027%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 0%;
  margin-left: 0%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 100%;
}
.area > .slim .tiny {
  width: 100%;
}
.farnorth {
  width: 100%;
}
.farnorth > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farnorth h2,
.farnorth .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.farnorth .part,
.farnorth > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.farnorth > .slim .part,
.farnorth > .slim.grid table {
  width: 100%;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
.content .wide {
  width: 66.11165005%;
}
.north > .slim:first-child {
  margin-top: 36px;
}
.area > .slim {
  left: 0;
}
.north > .unit {
  margin-top: 36px;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.farnorth {
  float: right;
  clear: right;
  width: 32.96193129%;
}
@media screen and (min-width: 968px) {
  .farnorth {
    width: 30.73351903%;
  }
}
.content > .area {
  margin-left: -0.27855153%;
  width: 100.55710306%;
}
.cb-layout1 .north {
  margin-left: -3px;
  width: calc(100% + 6px);
}
.cb-layout1 .north .unit {
  margin-left: 3px;
  margin-right: 3px;
  width: calc(100% - 6px);
}
.cb-layout1 .north .unit.slim {
  width: calc((100% / 3) - 6px);
}
.cb-layout1 .north .unit .part {
  width: 100%;
}
@media screen and (min-width: 1168px) {
  .farnorth .part {
    width: 113% !important;
  }
}
@media screen and (min-width: 1368px) {
  .farnorth .part {
    width: 140% !important;
  }
}
.cb-layout2 .main > .wide:first-child {
  margin-bottom: 40px;
}
.cb-layout2 .main > .slim.seam,
.cb-layout2 .main > .slim.flat {
  min-height: 21.45em;
}
.cb-layout2 .main > .slim.seam .body,
.cb-layout2 .main > .slim.flat .body {
  margin-bottom: 0.2em;
}
.cb-layout2 .main > .slim.flat {
  min-height: 20.1em;
}
.content .wide .tall {
  width: 98%;
}
.main .form .foot {
  width: 98%;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 10%;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*# sourceMappingURL=./screen-large.css.map */